<template>
  <PageWithLayout>
    <Board
      :boardData="viewModel.boardData"
      :searchParams.sync="viewModel.searchParams"
      :dataList="viewModel.dataList"
      :paginationData="viewModel.paginationData"
      :emptyDesc="viewModel.CARD_PURCHASE.searched && !viewModel.CARD_PURCHASE.hasData ? '검색결과가 없습니다' : '조회중입니다'"
      @onClickRow="(rowId,rowData) => viewModel.onClickRow(rowId,rowData)"
      @onSearch="viewModel.getCardPurchaseList()">
    </Board>
    <template v-slot:popup>
      <KbCardPurchaseTransactionsPopup
        v-if="viewModel.isKbCardPurchaseTransactionsPopup"
        :id="viewModel.KbCardPurchaseTransactionsId"
        @onClickClose="viewModel.onClickCloseKbCardPurchaseTransactionsPopup()"/>
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import Board from '@lemontree-ai/lemontree-admin-common-front/components/common/board/Board';
// // popup
import KbCardPurchaseTransactionsPopup from '@/views/cs/card/purchase/view/popup/KbCardPurchaseTransactionsPopup'
// // viewModel
import KbCardPurchaseListViewModel from '@/views/cs/card/purchase/viewModel/KbCardPurchaseListViewModel'

export default {
  name: 'KbCardPurchaseList',
  components:{
    PageWithLayout,
    Board,

    KbCardPurchaseTransactionsPopup
  },
  beforeMount(){
    this.viewModel.init()
  },
  data(){
    return{
      viewModel: new KbCardPurchaseListViewModel(),
    }
  },
}
</script>
<style scoped>
.board_comm::v-deep .tbl_comm table{min-width:100%;width:auto}
.board_comm::v-deep .tbl_comm table .txt_tbl{display:block;max-width:200px}
.board_comm::v-deep .tbl_comm table .td_mark .txt_tbl{display:inline-block}
</style>