<template>
  <Popup
    title="실 거래 내역"
    :maxWidth="1100"
    closeBtnText="닫기"
    @onClickClose="$emit('onClickClose')">
    <Board
      :boardData="viewModel.boardData"
      :searchParams.sync="viewModel.searchParams"
      :dataList="viewModel.dataList"
      :paginationData="viewModel.paginationData"
      :emptyDesc="viewModel.boardData.searched && !viewModel.boardData.hasData ? '실 거래 내역이 없습니다.' : '조회중입니다'"
      @onSearch="viewModel.getCardPurchaseTransactions()">
    </Board>
  </Popup>
</template>

<script>
import Popup from '@lemontree-ai/lemontree-admin-common-front/components/layout/popup/Popup';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
import Board from '@lemontree-ai/lemontree-admin-common-front/components/common/board/Board';
// viewModel
import KbCardPurchaseTransactionsViewModel from '@/views/cs/card/purchase/viewModel/KbCardPurchaseTransactionsViewModel'

export default {
  name:'KbCardPurchaseTransactionsPopup',
  props:{
    id: Number
  },
  components:{
    Popup,
    TableView,
    Button,
    Board
  },
  beforeMount(){
    this.viewModel.init(this.id)
  },
  data(){
    return{
      viewModel: new KbCardPurchaseTransactionsViewModel(),
    }
  },
}
</script>
<style lang="scss" scoped>
</style>