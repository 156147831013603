import store from '@lemontree-ai/lemontree-admin-common-front/store/index';
import apiPath from '@/services/apiPath';
import { GET_REQUEST } from '@/services/http';

export default class KbCardPurchaseTransactionsViewModel {
  constructor() {
    this.dataList = [];
    this.searchParams = {
      page:0,
      pageSize:10,
      direction: 'DESC'
    };
    this.paginationData = {
      totalCount:0,
      totalPage:0
    };
    this.boardData = {
      // title:'실 거래 내역',
      drawDataList: [
        {
          title:'ID',
          width:'60px',
          value:'id',
          isAlignLeft: true,
        },
        {
          title:'생성일시',
          width:'100px',
          value:'createdAt',
          class:{
            name: 'fs_small'
          },
          valueCustom:[
            { 
              type: 'value',
              value: 'createdAt',
              filter: { name:'convertDateFormat', value:'YYYY-MM-DD' },
            },
            { 
              type: 'text',
              value: '<br>',
            },
            { 
              type: 'value',
              value: 'createdAt',
              filter: { name:'convertDateFormat', value:'HH:mm:ss' },
            },
          ],
          isAlignLeft: true,
        },
        {
          title:'업데이트일시',
          width:'100px',
          value:'updatedAt',
          class:{
            name: 'fs_small'
          },
          valueCustom:[
            { 
              type: 'value',
              value: 'updatedAt',
              filter: { name:'convertDateFormat', value:'YYYY-MM-DD' },
            },
            { 
              type: 'text',
              value: '<br>',
            },
            { 
              type: 'value',
              value: 'updatedAt',
              filter: { name:'convertDateFormat', value:'HH:mm:ss' },
            },
          ],
          isAlignLeft: true,
        },
        {
          title:'거래일시',
          width:'94px',
          value:'transactionAt',
          filter: { name:'convertDateFormat', value:'YYYY-MM-DD HH:mm:ss' },
          isAlignLeft: true,
        },
        {
          title:'거래 타입',
          width:'90px',
          value:'transactionType',
          isAlignLeft: true,
        },
        {
          title:'거래금액',
          width:'100px',
          value:'transactionAmount',
          filter: { name:'convertNumberToComma', value:false, value2:'0원', value3:'원' },
          isAlignRight: true,
        },
        {
          title:'포인트금액',
          width:'100px',
          value:'pointAmount',
          filter: { name:'convertNumberToComma', value:false, value2:'0원', value3:'원' },
          isAlignRight: true,
        },
        {
          title:'할인금액',
          width:'100px',
          value:'discountAmount',
          filter: { name:'convertNumberToComma', value:false, value2:'0원', value3:'원' },
          isAlignRight: true,
        },
        {
          title:'상태',
          width:'100px',
          value:'status',
          isAlignLeft: true,
        },
        {
          title:'카드번호',
          width:'150px',
          value:'cardNumber',
          isAlignLeft: true,
        },
        {
          title:'user ID',
          width:'60px',
          value:'userId',
          isAlignLeft: true,
        },
        {
          title:'user UID',
          width:'180px',
          value:'userUid',
          isAlignLeft: true,
        },
        {
          title:'가맹점명',
          width:'100px',
          value:'affiliateName',
          isAlignLeft: true,
        },
        {
          title:'업종코드',
          width:'80px',
          value:'affiliateCode',
          isAlignLeft: true,
        },
        {
          title:'가맹점 타입',
          width:'100px',
          value:'affiliateType',
          isAlignLeft: true,
        },
        {
          title:'가맹점 번호',
          width:'150px',
          value:'affiliateNumber',
          isAlignLeft: true,
        },
        {
          title:'승인번호',
          width:'100px',
          value:'approveNumber',
          isAlignLeft: true,
        },
        {
          title:'isBenefit',
          width:'70px',
          value:'isBenefit',
          filter: { name:'convertIdToText', value:'common_boolean' },
          isAlignLeft: true,
        },
        {
          title:'ipAddress',
          width:'100px',
          value:'ipAddress',
          isAlignLeft: true,
        },
        {
          title:'외부 거래 ID',
          width:'130px',
          value:'externalTransactionId',
          isAlignLeft: true,
        },
        {
          title:'trace ID',
          width:'180px',
          value:'traceId',
          isAlignLeft: true,
        },
        {
          title:'설명',
          width:'100px',
          value:'description',
          isAlignLeft: true,
        },
        {
          title:'memo',
          width:'100px',
          value:'memo',
          isAlignLeft: true,
        },
      ],
      option:{
        isTotal: false,
        isSize: false,
        // rowIdValue: 'cardApplicationId'
      },
      searched: false,
      hasData: true,
    }
  }
  init(id){
    this.getCardPurchaseTransactions(id);
  }
  // [API] 실 거래 내역
  getCardPurchaseTransactions(id){
    this.boardData.searched = false;
    const path = `${apiPath.CS_KB_CARD_PURCHASE_TRANSACTIONS.format(id)}`;
    GET_REQUEST(path).then(
    (success) => {
      this.boardData.searched = true;
      const resultData = success.data;
      console.log(resultData)
      this.boardData.hasData = resultData.length > 0;
      this.dataList = resultData;

      this.paginationData.totalCount = resultData.length;
      // this.paginationData.totalPage = resultData.totalPage;
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
}