import store from '@lemontree-ai/lemontree-admin-common-front/store/index';
import apiPath from '@/services/apiPath';
import { GET_REQUEST } from '@/services/http';
import { makeQueryStringByObject } from '@lemontree-ai/lemontree-admin-common-front/utils/urlUtils';

export default class CardReturnListViewModel {
  constructor() {
    this.dataList = [];
    this.searchParams = {
      page:0,
      pageSize:10,
      direction: 'DESC'
    };
    this.paginationData = {
      totalCount:0,
      totalPage:0
    };
    this.boardData = {
      title:'매입취소 목록',
      drawDataList: [
        {
          title:'ID',
          width:'40px',
          value:'id',
          isAlignLeft: true,
        },
        {
          title:'카드 ID',
          width:'40px',
          value:'cardId',
          isAlignLeft: true,
        },
        {
          title:'승인번호',
          width:'80px',
          value:'approveNumber',
          isAlignLeft: true,
        },
        {
          title:'퍼핀 UID',
          width:'150px',
          value:'firfinUserUid',
          isAlignLeft: true,
        },
        {
          title:'거래 ID',
          width:'150px',
          value:'traceId',
          isAlignLeft: true,
        },
        {
          title:'거래일',
          width:'94px',
          value:'transactionDate',
          isAlignLeft: true,
        },
        {
          title:'거래금액',
          width:'100px',
          value:'transactionAmount',
          filter: { name:'convertNumberToComma', value:false, value2:'0원', value3:'원' },
          isAlignRight: true,
        },
        {
          title:'상태',
          width:'80px',
          value:'status',
          isAlignLeft: true,
        },
        {
          title:'생성일시',
          // width:'154px',
          width:'100px',
          value:'createdAt',
          filter: { name:'convertDateFormat', value:'YYYY-MM-DD HH:mm:ss' },
          isAlignLeft: true,
        },
        {
          title:'부분취소<br />횟수',
          width:'50px',
          value:'partialCancelCount',
        },
        {
          title:'취소누적<br />금액',
          width:'80px',
          value:'cancelAmount',
          filter: { name:'convertNumberToComma', value:false, value2:'0원', value3:'원' },
        },
        {
          title:'매입취소<br>무시',
          width:'88px',
          value:'isBtnDisabled',
          buttonCondition: [
            { condition:false, text:'무시', size:'small', style:'secondary_border', disabled:false, onClickEvent: (data) => this.onClickCancel(data) },
            { condition:true, text:'무시', size:'small', style:'secondary_border', disabled:true },
          ],
        },
        {
          title:'매입환불<br>적용',
          width:'80px',
          value:'isBtnDisabled',
          buttonCondition: [
            { condition:false, text:'환불', size:'small', style:'primary_border', disabled:false, onClickEvent: (data) => this.onClickReturn(data) },
            { condition:true, text:'환불', size:'small', style:'primary_border', disabled:true },
          ],
        },
      ],
      option:{
        isTotal: true,
        isSize: true,
        // rowIdValue: 'cardApplicationId'
      },
    }

    this.CARD_PURCHASE ={
      searched: true,
      hasData: false,
      responseDate:'',
    }
    this.isKbCardPurchaseTransactionsPopup = false;
    this.KbCardPurchaseTransactionsId = null;
  }
  init(){
    this.getCardPurchaseList();
  }
  onClickRow(rowId,rowData){
    this.KbCardPurchaseTransactionsId = rowId;
    this.isKbCardPurchaseTransactionsPopup = true;
  }
  onClickCloseKbCardPurchaseTransactionsPopup(){
    this.isKbCardPurchaseTransactionsPopup = false;
    this.KbCardPurchaseTransactionsId = null;
  }
  isNotIssue(data){
    console.log(data.status)
    return data.status != 'ISSUED'
  }
  onClickCancel(data){
    const requestCancel = () => {
      this.getCardPurchaseCancel(data);
    }
    store.dispatch('commonAlert/fetchAlertStart', {
      alertMsg: `매입취소를 무시하시겠습니까?`,
      closeBtnText: '취소',
      compeleteBtnText: '매입취소 무시',
      confirmCallBack: requestCancel,
    });
  }
  onClickReturn(data){
    const requestReturn = () => {
      this.getCardPurchaseReturn(data);
    }
    store.dispatch('commonAlert/fetchAlertStart', {
      alertMsg: `매입환불을 적용하시겠습니까?`,
      closeBtnText: '취소',
      compeleteBtnText: '매입환불 적용',
      confirmCallBack: requestReturn,
    });
  }
  // [API] 매입취소 목록
  getCardPurchaseList(){
    this.CARD_PURCHASE.searched = false;
    const query = makeQueryStringByObject(this.searchParams);
    const path = `${apiPath.CS_KB_CARD_PURCHASE_LIST}${query}`;
    GET_REQUEST(path).then(
    (success) => {
      this.CARD_PURCHASE.searched = true;
      const resultData = success.data;

      let dataList = resultData.data;
      this.dataList = dataList.map(item => {
        let rObj = item;
        rObj.isBtnDisabled = Boolean(item.status != 'ISSUED')
        return rObj;
      });
      this.CARD_PURCHASE.hasData = resultData.total > 0;

      this.paginationData.totalCount = resultData.total;
      this.paginationData.totalPage = resultData.totalPage;
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // [API] 매입취소 무시
  getCardPurchaseCancel(data){
    const path = `${apiPath.CS_KB_CARD_PURCHASE_CANCEL.format(data.id)}`;
    GET_REQUEST(path).then(
    (success) => {
      store.dispatch('commonToast/fetchToastStart', '매입취소가 무시되었습니다.');
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // [API] 매입환불 적용
  getCardPurchaseReturn(data){
    const path = `${apiPath.CS_KB_CARD_PURCHASE_RETURN.format(data.id)}`;
    GET_REQUEST(path).then(
    (success) => {
      store.dispatch('commonToast/fetchToastStart', '매입환불이 적용되었습니다.');
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
}